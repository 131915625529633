import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../styles/ValueProposition.css';
import { registerUrl, DefaultGtag } from '../utils/misc'
import '../styles/WhyTas.css'
import '../styles/index.css'
import '../styles/testimonials.css'
import JoinUsPage from '../components/JoinUs';
import StarIcon from '@mui/icons-material/Star';

const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const firstSection = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "80rem",
  width: "100%",
  margin: "auto",
  padding: "1rem 1rem",
}

const TestimonialsPage = () => {
  const { t } = useTranslation();
  const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=all')

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
    setRUrl(registerUrl())
  }, [])

  return (
    <Layout>
      <main style={mainStyles} id="testimonials">
        <div style={firstSection} className="page">
          <h1 style={{ marginInline: "auto", textAlign: "center", maxWidth: "40rem" }}>{t('testimonials.title')}</h1>
          <div className="main-testimony">
            <div className="testimony-title">Hotel Ciputra Jakarta</div>
            <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
            <div style={{ marginTop: "0.5rem" }}>{t('testimonials.hcj.desc')}</div>
            <div style={{ display: "flex", marginTop: "1.5rem" }}>
              <Link to='/article/hotel-ciputra-jakarta-enhances-guest-experience-with-seamless-ordering-solution/'><div className="tas-button">{t('testimonials.read')}</div></Link>
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <div className="testimony">
              <div className="testimony-title">Nilen Ramsamy <span style={{ color: "rgba(0,0,0,0.4)" }}> - Chef Nilen Restaurant</span></div>
              <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
              <div>{t('testimonials.nilen.desc')}</div>
              <div className="view-on-google"><a href="https://g.co/kgs/Szj8nx" target="_blank" rel="nofollow">{t('testimonials.view')}</a></div>
            </div>
            <div className="testimony">
              <div className="testimony-title">INHB WORLDWIDE <span style={{ color: "rgba(0,0,0,0.4)" }}> - iFOOD SAFARI APP</span></div>
              <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
              <div>{t('testimonials.inhb.desc')}</div>
              <div className="view-on-google"><a href="https://g.co/kgs/UKbA1c" target="_blank" rel="nofollow">{t('testimonials.view')}</a></div>
            </div>
            <div className="testimony">
              <div className="testimony-title">Yinka Bello <span style={{ color: "rgba(0,0,0,0.4)" }}> - Go2Lounge45 Restaurant</span></div>
              <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
              <div>{t('testimonials.yinka.desc')}</div>
              <div className="view-on-google"><a href="https://g.co/kgs/V1TGvm" target="_blank" rel="nofollow">{t('testimonials.view')}</a></div>
            </div>
            <div className="testimony">
              <div className="testimony-title">Rohan Jha <span style={{ color: "rgba(0,0,0,0.4)" }}> - FoodShop Restaurant</span></div>
              <div className="stars"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
              <div>{t('testimonials.rohan.desc')}</div>
              <div className="view-on-google"><a href="https://g.co/kgs/1bHNhK" target="_blank" rel="nofollow">{t('testimonials.view')}</a></div>
            </div>
            <div className="testimony">
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ fontSize: "200%", fontWeight: 500, marginBottom: "1rem" }}>9.2 / 10</div>
                <div>{t('testimonials.overall.desc')}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <JoinUsPage /> */}
      </main>
    </Layout>
  )
}

export default TestimonialsPage

export const Head = () => <>
  <DefaultGtag />
  {/* <link rel="canonical" href="https://takeaseat.io/" /> */}
  <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/testimonials/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/testimonials/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/testimonials/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/testimonials/" />
  <title>Customer testimonials for Take a Seat, the digital partner of the hospitality industry.</title>
  <meta name="description" content="Customer testimonials for Take a Seat, the digital partner of the hospitality industry." />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="The digital partner of restaurants and bars" />
  <meta property="og:description" content="Customer testimonials for Take a Seat, the digital partner of the hospitality industry." />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:url" content="https://takeaseat.io/testimonials/" />
  <meta property="og:site_name" content="Take a Seat testimonials" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="The digital partner of restaurants and bars" />
  <meta name="twitter:description" content="Customer testimonials for Take a Seat, the digital partner of the hospitality industry." />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies", "testimonials", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;