import React, { useState, useEffect } from "react"
import '../styles/JoinUs.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { registerUrl } from '../utils/misc'
import ReplyIcon from '@mui/icons-material/Reply';


const restaurants = [
    ["view-concept-demo", "saudi-arabia"],
    ["bubble-pop-demo", "turkey"], // 
    ["holybasileate-demo", "south-africa"],
    ["hotel-omorika-demo", "serbia"],
    ["shell-beans-demo", "maldives"],
    ["linger-trolley-demo", "vietnam"],
    ["green-garden-vege-cafe-demo", "malaysia"], //green-garden-vege-cafe
    ["sushi-koi-demo", "malaysia-2"],
    ["rabbitholefood-demo", "malaysia-3"],
    ["supertiuna-demo", "nigeria"], // 
    ["ubana-grill-house-demo", "nigeria-2"], // 
    ["tenno-demo", "lebanon"],
    ["saint-cafe-demo", "bangladesh"], //saint-cafe
    ["pantheon-demo", "pakistan"],
    ["addict-burger-demo", "oman"],
    ["richiamo-demo", "venezuela"],
    ["212-lounge-demo", "uk"],
    ["le-nixon-demo", "la-reunion"], //le-nixon
    ["the501hub-demo", "belize"],
    ["the-secret-garden-demo", "india"],
    ["collectors-cafe-demo", "qatar"],
    ["master-pizza-demo", "mexico"],
    ["skin-joint-demo", "mexico-2"]
]

const isBrowser = typeof window !== "undefined"

function Testimonial({ text, author, i }) {
    return <div className={'testimonial' + (!text ? " empty" : "")} name={`testimonial-${i}`}>
        <div className='txt'>{text}</div>
        <div className='author'><i>{author}</i></div>
    </div>
}

function isInViewPort(ele) {
    if (!isBrowser) return false

    var rect = ele.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

function TestimonialC() {
    const [testimonialSelected, setTestimonialSelected] = useState(1)
    const [sliderInView, setSliderInView] = useState(false)
    const { t } = useTranslation('joinus');

    useEffect(() => {
        setInterval(() => {
            const ele = document.getElementById("testimonials")
            if (ele && isInViewPort(ele)) {
                setSliderInView(true)
            } else {
                setSliderInView(false)
            }
        }, 1000)
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                if (sliderInView) {
                    const newTestimonialSelected = (testimonialSelected + 1) % 5
                    setTestimonialSelected(prev => newTestimonialSelected)
                    const ele = document.getElementsByName(`testimonial-${newTestimonialSelected}`)
                    if (ele.length > 0) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
                }
            } catch (e) {
                console.log(e)
            }
        }, 4000)
        return () => clearTimeout(timer);
    }, [testimonialSelected, sliderInView])

    return <div className='testimonials-c'>
        <div style={{ fontSize: "1.25em", margin: "1rem auto" }}>{t('testimonials.satisfaction')}: <b>9.2 / 10</b></div>
        <div>

            <div className='testimonials' id="testimonials">
                <Testimonial i={0} text={"I was convinced to use Take a Seat because of how user friendly it is."} author="Flamingo R." />
                <Testimonial i={1} text={"Feasibility and accessibility to operate without any hassle."} author="Kutumb C." />
                <Testimonial i={2} text={"Quicker & more efficient ordering."} author="QR menus user" />
                <Testimonial i={3} text={"Very easy for non tech savvy person."} author="Kutumb C." />
                <Testimonial i={4} text={"I believe Take a Seat will make my business more visible to customers."} author="Bolan" />
            </div>
        </div>
        <div className='selector-c'>
            {[...Array(5).keys()].map(i =>
                <div
                    key={`selector-${i}`}
                    className={'selector' + (testimonialSelected === i ? " selected" : "")}
                    onClick={() => {
                        const ele = document.getElementsByName(`testimonial-${i}`)
                        if (ele.length > 0) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
                        setTestimonialSelected(i)
                    }}
                >•</div>
            )}
        </div>
    </div>
}


function JoinUsPage() {
    const { t } = useTranslation();
    const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=digital-menu')

    useEffect(() => {
        setRUrl(registerUrl())
    }, [])

    return <div id="join-us" className="page">
        <div className="main-container">
            <h2 className="title" style={{ textAlign: "center", marginTop: "4rem" }}>
                <span>{t('join-us-first-title.0')}</span>
                <br />
                <br />
                <span>{t('join-us-first-title.1')}</span>
            </h2>
            <div className="figures">
                <div><b>2 000+</b>{t('join-us-subtitle.0')}</div>
                <div><b>400 000+</b>{t('join-us-subtitle.1')}</div>
                <div>{t('join-us-subtitle.2.0')}<b>25+</b>{t('join-us-subtitle.2.1')}</div>
            </div>
            <div className="refs">
                <picture style={{ display: "flex", alignItems: "center" }}>
                    <source srcSet="/static/media/images/world-map-free-cleaned-largerdots.webp" type="image/webp" width="960" height="480" loading="lazy" />
                    <source srcSet="/static/media/images/world-map-free-cleaned-largerdots.png" type="image/png" width="960" height="480" loading="lazy" />
                    <img alt="world map" width="960" height="480" loading="lazy" />
                </picture>
                <div
                    className="click-to-view"
                    style={{
                        position: "absolute",
                        zIndex: "1"
                    }}>
                    <div style={{
                        display: "flex",
                        position: "relative"
                    }}>
                        <div style={{
                            background: "white",
                            padding: "0.25rem 0.5rem",
                            border: "2px solid",
                            borderRadius: "4px",
                            fontWeight: "bold"
                        }}>Click to view</div>
                        <div style={{
                            transform: "scaleX(-1) rotate(-160deg)",
                            position: "absolute",
                            left: "-1.75rem",
                            bottom: "-2.5rem",
                        }}><ReplyIcon style={{
                            fontSize: "250%"
                        }} /></div>
                    </div>
                </div>
                {restaurants.map((r, i) => <div key={`map-pin-${i}`} className={"map-pin " + (r[1])}><a href={`https://${r[0]}.tas.ink/menu?context=landing-page`} target="_blank">
                    <img src="/static/media/icons/map-pin.svg" alt="map pin" width="960" height="480" loading="lazy" />
                </a></div>)}
            </div>
            <TestimonialC />
            <div style={{ display: "flex", marginBlock: "4rem" }} className='tas-button-wrapper'>
                <a href={rUrl} rel="me" className="tas-button"
                    onClick={() => {
                        if (isBrowser) {
                            window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.joinus")
                            window.gtag('event', 'click_on_get_started');
                        }
                    }}>
                    <div>{t('free-sign-up')}</div>
                </a>
            </div>
        </div>
    </div>
}

export default JoinUsPage;
